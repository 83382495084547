<template>
  <nav>
    <div>
        <img width="110" height="50" src="@/assets/logo.png" class="logo" alt=""
          sizes="(max-width: 110px) 100vw, 110px">
    </div>
  </nav>
</template>
<style lang="scss">
nav{
  height: 8vh;
}
.logo {
  float:left;
  height: 7vh;
  width: auto;
  margin-top: .5vh!important;
  margin-left: .5vw!important;
}
</style>
<script>
export default {
  name: 'Home',

  methods: {
    myFunction () {
      var x = document.getElementById('myTopnav')
      if (x.className === 'topnav') {
        x.className += ' responsive'
      } else {
        x.className = 'topnav'
      }
    }
  }
}
</script>
