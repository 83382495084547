<template>
  <div>
    <div class="main-fotos">
      <h5><strong>FOTOS</strong></h5>
      <div class="grid-4">
        <div>
          <div class="box"><img src="@/assets/box-01.jpg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-02.jpg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-03.jpg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-04.jpg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-05.jpg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-06.jpeg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-07.jpeg"></div>
        </div>
        <div>
          <div class="box"><img src="@/assets/box-09.jpg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.main-fotos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .grid-4 {
    margin-bottom: 4rem!important;
    width: auto !important;
    display: grid;
    grid-template-columns: repeat(4, 152px);

    .box>img {
      max-width: 150px;
      max-height: 150px;
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(2, calc(100vw/2));

      .box>img {
        max-width: calc(100vw/2);
        max-height: auto;
      }
    }
  }
}
</style>
