<template>
  <div class="home">
    <Banner/>
    <Inicio msg="Bem Vindo a Andre Mudanças"/>
    <Fotos/>
    <Embalagem/>
    <BannerInf/>
  </div>
</template>
<script>
import Inicio from '@/components/Inicio.vue'
import Banner from '@/components/Banner.vue'
import Fotos from '@/components/Fotos.vue'
import Embalagem from '@/components/Embalagem.vue'
import BannerInf from '@/components/BannerInf.vue'
export default {
  name: 'Home',
  components: {
    Banner,
    Inicio,
    Fotos,
    Embalagem,
    BannerInf
  }
}
</script>
