<template>
  <footer>
    <div class="art cl-333">
      <div class="flex-container mg-top-24">
        <div><a
            href="https://api.whatsapp.com/send?phone=5519974114092&amp;text=Olá%20Andre%20Mudanças,%20gostaria%20de%20um%20orçamento."
            class="btn-whats flex-col-fixed"><span><img src="@/assets/whatsapp-32.svg"></span></a>
          <h5 class="mg-bottom-24">ANDRE MUDANÇAS EM GERAL</h5>
          <div>
            <div class="mg-top-24 mg-bottom-24"><a href="tel:19984195480" class="cl-333"><img
                  src="@/assets/phone-16.svg">&nbsp;(19)&nbsp;9&nbsp;8419-5480</a></div>
            <div class="mg-top-24 mg-bottom-24"><a
                href="https://api.whatsapp.com/send?phone=5519974114092&amp;text=Olá%20Andre%20Mudanças,%20gostaria%20de%20um%20orçamento."
                class="cl-333"><img src="@/assets/whatsapp-16.svg">&nbsp;(19)&nbsp;9&nbsp;7411-4092</a></div>
            <div class="mg-top-12"><a href="mailto:contato@andremudancasemgeral.com.br" class="cl-333"><img
                  src="@/assets/envelope-16.svg">&nbsp;contato@andremudancasemgeral.com.br</a></div>
          </div>
          <h5 class="cl-333 mg-top-24 mg-bottom-24">Siga-nos</h5>
          <div class="mg-top-24"><a href="https://www.instagram.com/andremudancasemgeral/"><span><img
                  src="@/assets/instagram-logo-32.svg"></span></a>&nbsp; <a
              href="https://www.facebook.com/andremudancasemgeral/"><span><img
                  src="@/assets/facebook-logo-32.svg"></span></a></div>
          <p class="mg-top-24 mg-bottom-24">Copyright&nbsp;©&nbsp; {{ year }} - Todos os direitos reservados</p>
        </div>
      </div>
    </div>
</footer>
</template>
<style lang="scss">
footer {
  border-top: solid 2px #ff0;
  margin-top: 2rem;

  * {
    text-decoration: none;
    color: #333333
  }

  .btn-whats {
    margin: 25px 0 !important;
    background-color: #057e01;
    overflow: hidden !important;
    border-radius: 100% !important;
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 40px;
    right: 40px;
    z-index: 997;
  }

  .flex-col-fixed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
  }
}
</style>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
