<template class="mainBanerInf">
  <div class="banner-div-02  dark-strong dark cl-ffff00">

    <div class="mg-top-24">
      <h3 class="mg-top-24">SEGURANÇA QUALIDADE E TRANQUILIDADE NA HORA DE REALIZAR A SUA MUDANÇA.
      </h3>
    </div>
    <div>
      <h4 class="mg-top-24">Mudanças Residenciais e Comerciais ou Transportes de Produtos/Mercadorias.</h4>
    </div>
    <div class="grid-3">
      <div class="mg-top-24"><img src="@/assets/icon-quality.svg">
        <h5>Qualidade</h5>
      </div>
      <div class="mg-top-24"><img src="@/assets/icon-pont.svg">
        <h5>Pontualidade</h5>
      </div>
      <div class="mg-top-24"><img src="@/assets/icon-just.svg">
        <h5>Preço justo</h5>
      </div>
    </div>
  </div>
</template>
<style lang="scss">

.mainBanerInf {
  .banner-div-02 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

}
.mainBanerInf .grid-3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  margin-top: 24px!important;
  div>p{
    padding: .5rem!important;
  }
}
</style>
