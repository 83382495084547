<template>
  <section>
    <div class="grid-2">
      <div>
        <div>
          <h3 class="heading-title">Embalagens para mudança</h3>
        </div>
        <div>
          <div>
            <p class="descripition">ANDRÉ MUDANÇAS quer que você aproveite da melhor experiência possível em sua mudança.
              Para isso, são utilizados materiais de embalagem profissionais para
              garantir que seus pertences sejam transportados de forma eficaz e eficiente.</p>
          </div>
          <div  >
            <a href="https://api.whatsapp.com/send?phone=5519974114092&amp;text=Olá%20Andre%20Mudanças,%20gostaria%20de%20saber%20mais%20informações%20sobre%20embalagem%20para%20mudanças." target="_blank" class="btn">
              <span>
                <span class="button-text">Solicite mais informações</span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <img width="500" height="250" src="@/assets/embalagem.png" alt="Agrupar 1 copiar 2" loading="lazy"
          srcset="@/assets/embalagem.png 901w, @/assets/embalagem.png 300w, @/assets/embalagem.png 768w"
          sizes="(max-width: 500px) 100vw, 500px" title="Empresa de Mudanças 10">
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  div{
    margin-top: 2rem!important;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

}
.descripition{
  padding: 1rem!important;
}

  .btn{
  border: 0;
  border-radius: 50px;
  padding: .75rem 2.75rem!important;
  color: #333;
  box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.25);
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #fff000;
  @media only screen and (max-width: 768px) {
    max-width: 80vw;
  }

  &:hover {
    -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
    box-shadow: 0 1rem 1.5rem rgba(0,0,0,.25);
  }
  &*{
    color: #fff!important;
  }
  }
.heading-title {
  color: #000;
  font-family: bignoodlething, Sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 1px;
}
</style>
