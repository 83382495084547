<template>
  <div class="banner-div dark-strong dark cl-ffff00 ">
    <h1 class="flex-container txt-left quadrado ft-left">BEM VINDOS<br >A ANDRE
      MUDANÇAS EM GERAL.</h1>
  </div>
</template>
<style lang="scss">
.banner-div-02, .banner-div {
    width: 100%!important;
    position: relative;
    background-attachment: fixed;
    background-image: url(../assets/banner-01.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.cl-ffff00 {
    color: #ff0;
}
.banner-div, .banner-div-02 {
    height: 100vh!important;
}
.dark:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #191919;
    opacity: .52;
    z-index: 1;
}
.dark-strong:after {
    background: #000000ab;
    opacity: .9;
}
.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row!important;
}
.txt-left {
    text-align: left!important;
    padding-left: 12px!important;
}
.ft-left {
    float: left;
}
.quadrado {
    width: 100%!important;
    height: 100%!important;
    -webkit-animation: go-back 1s;
    animation: go-back 1s;
}
h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    text-shadow: 2px 2px 4px #000000ab;
    z-index: 99999;
}
h1, h2, h3, h4, h5, h6 {
    font-family: Oswald;
    line-height: 1.42;
    font-weight: 400;
    text-transform: uppercase;
}
h1 {
    font-size: 6.0833333333333vw!important;
}
.flex-container {
    flex-direction: column!important;
}
@keyframes go-back{
  0% {
    transform: translateX(-100%);
}
100% {
    transform: translateX(48px);
}
}
</style>
