<template class="mainInicio">
  <section>
      <h5><strong>NOSSOS SERVIÇOS</strong></h5>
      <div class="grid-3">
        <div>
          <div><img src="@/assets/icon-home.svg"></div>
          <h5><strong>MUDANÇAS</strong></h5>
          <p class="mg-top-24 mg-bottom-24">Mudança Residencial e Comercial para Campinas e região, ou
            longas distâncias (interestaduais), com o melhor preço.</p>
        </div>
        <div>
          <div><img src="@/assets/icon-tools.svg"></div>
          <h5><strong>MONTAGEM DE MÓVEIS</strong></h5>
          <p class="mg-top-24 mg-bottom-24">Além do transporte, temos serviço de Desmontagem e Montagem de
            Móveis para Mudanças Comerciais e Residenciais.</p>
        </div>
        <div>
          <div><img src="@/assets/icon-truck.svg"></div>
          <h5><strong>FRETES COMERCIAIS</strong></h5>
          <p class="mg-top-24 mg-bottom-24">Caminhão Baú para transporte de Produtos ou Mercadorias. Apoio
            logístico para seu Comércio ou Empresa.</p>
        </div>
      </div>
  </section>
  <section>
    <div>
      <h3><strong>Transporte em veículos fechados. Segurança e Tranquilidade na hora da mudança.</strong></h3>
      <h5 class="mg-top-24 mg-bottom-24">Caminhão Baú para realizar Mudanças Residenciais e Comerciais ou Fretes
        de Produtos/Mercadorias.</h5>
    </div>
  </section>
  <section>
    <div>
      <h5><strong>ATENDIMENTO</strong></h5>
      <div class="grid-2">
        <div>
          <div><img src="@/assets/img-24h.svg" width="200" height="200"></div>
          <h5 class="cl-333">Operação 24 Horas por dia</h5>
        </div>
        <div>
          <div><img src="@/assets/img-map.svg" width="200" height="200"></div>
          <h5 class="cl-333">Todo Territorio Nacional</h5>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.mainInicio .grid-3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  div>p{
    padding: .5rem!important;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1,100%);
  }
}
.grid-2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1,100%);
  }
}
</style>
