<template id="main">
    <NavBar/>
    <router-view/>
    <Footer/>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }
}
</script>
<style lang="scss">
*{
  text-decoration: none!important;
  list-style: none!important;
  max-width: 100vw!important;
  text-align: center!important;
  padding: 0!important;
  margin: 0!important;
}
html,body,#app{
  max-width: 100vw!important;
  overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F9F9;
  color:#333333
}
section{
  margin: 2rem 0!important;
}
#main{
  display: grid;
  grid-template-rows: repeat(3,1fr);
}
.mg-top-24{
  margin-top: 24px!important;
}
.mg-bottom-24{
  margin-bottom: 24px!important;
}
</style>
